var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("v-textarea", {
        attrs: {
          value: _vm.value.answer_id,
          outlined: "",
          readonly: _vm.readonly,
        },
      }),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "7" } },
            [
              _vm.value.file_url
                ? _c(
                    "v-btn",
                    {
                      attrs: { width: "150", color: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.$emit("preview")
                        },
                      },
                    },
                    [_vm._v("Preview")]
                  )
                : _vm._e(),
            ],
            1
          ),
          _c("v-col", { staticClass: "mt-2", attrs: { cols: "1" } }, [
            _vm._v("Marks"),
          ]),
          _c(
            "v-col",
            [
              _c("v-text-field", {
                staticClass: "ml-4",
                attrs: {
                  outlined: "",
                  dense: "",
                  type: "number",
                  "hide-details": "",
                },
                on: { blur: _vm.submitScore },
                model: {
                  value: _vm.score,
                  callback: function ($$v) {
                    _vm.score = $$v
                  },
                  expression: "score",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }