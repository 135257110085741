var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.hasExplanation
    ? _c(
        "m-container",
        { staticClass: "px-0 py-0 q-a-card" },
        [
          _c(
            "v-row",
            { attrs: { justify: "center" } },
            [
              _c(
                "v-col",
                { staticClass: "py-1" },
                [
                  _c(
                    "v-expansion-panels",
                    { attrs: { flat: "" } },
                    [
                      _c(
                        "v-expansion-panel",
                        [
                          _c(
                            "v-expansion-panel-header",
                            { staticStyle: { "font-size": "16px" } },
                            [_vm._v("Explanation ")]
                          ),
                          _c(
                            "v-expansion-panel-content",
                            [
                              !_vm.$language
                                ? _c("p", [
                                    _vm._v(
                                      " The Correct Answer Explanation is indicated by "
                                    ),
                                    _c(
                                      "span",
                                      { staticStyle: { color: "#00C853" } },
                                      [_vm._v("Green")]
                                    ),
                                    _vm._v(" color. "),
                                  ])
                                : _c("p", [
                                    _vm._v(" সঠিক উত্তরের ব্যাখ্যা "),
                                    _c(
                                      "span",
                                      { staticStyle: { color: "#00C853" } },
                                      [_vm._v("সবুজ")]
                                    ),
                                    _vm._v(" রঙ দ্বারা নির্দেশ করা হয়েছে। "),
                                  ]),
                              _vm._l(_vm.filteredAnswer, function (answer) {
                                return _c("div", { key: answer.id }, [
                                  _c(
                                    "p",
                                    {
                                      staticClass: "mb-1",
                                      style: `color: ${
                                        _vm.styles.answer[answer.is_correct]
                                      }`,
                                    },
                                    [
                                      _vm._v(
                                        " Answer option " +
                                          _vm._s(answer.id) +
                                          " Explanation: "
                                      ),
                                    ]
                                  ),
                                  _c("p", [_vm._v(_vm._s(answer.explanation))]),
                                ])
                              }),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }