<template>
  <m-container
    style="padding: 1px !important; margin-bottom: 5px;"
    :class="{
      'selected-opt': selected,
      'correct-opt': correct,
      'wrong-opt': wrong
    }"
  >
    <v-hover v-slot:default="{ hover }">
      <div :class="{ 'opt-hover': hover }" style="margin: 1px;">
        <m-container class="pa-0">
          <v-row>
            <v-col
              cols="2"
              sm="1"
              md="1"
              class="pl-2 pr-0 py-2 d-flex align-center justify-center flex-grow-0"
            >
              <v-icon v-if="wrong" color="red accent-2">cancel</v-icon>
              <v-icon v-else-if="correct" color="teal darken-2"
                >check_circle</v-icon
              >
              <v-icon v-else-if="selected" color="primary">check_circle</v-icon>
              <v-icon v-else>radio_button_unchecked</v-icon>
            </v-col>
            <v-col class="pl-2 py-2 d-flex align-center">
              {{ answer.item_text }}
            </v-col>
            <v-col v-if="answer.file_url" class="mb-2 d-flex justify-center">
              <v-img
                :src="answer.file_url"
                max-width="300"
                max-height="300"
                contain
              ></v-img>
            </v-col>
          </v-row>
        </m-container>
      </div>
    </v-hover>
  </m-container>
</template>

<script>
export default {
  name: "AnswerOptP",
  components: {},
  mixins: [],
  props: {
    answer: { type: Object, required: true },
    selected: Boolean,
    correct: Boolean,
    wrong: Boolean
  },
  data() {
    return {};
  },
  computed: {},
  methods: {}
};
</script>

<style lang="scss">
.opt-hover {
  margin: 0 !important;
  border: 1px solid #c4c4c4 !important;
  border-radius: 50px !important;
  box-shadow: none !important;
}
.selected-opt {
  background: #e6f7ff;
  border-radius: 50px;
  margin-bottom: 8px;
}
.correct-opt {
  background: #defffb;
  border-radius: 50px;
  margin-bottom: 8px;
}
.wrong-opt {
  background: #ffebeb;
  border-radius: 50px;
  margin-bottom: 8px;
}
</style>
