<template>
  <div>
    <v-textarea
      :value="value.answer_id"
      outlined
      :readonly="readonly"
    ></v-textarea>
    <!-- <v-btn v-if="value.file_url" color="primary" @click="download"
      >Download Answer</v-btn
    >
    <div class="d-flex align-center">
      <v-text-field
        v-model="score"
        label="Score"
        outlined
        dense
        type="number"
        class="mt-3"
        hide-details
      ></v-text-field>
      <v-btn
        color="primary"
        class="ml-5 mt-2"
        :loading="submitting"
        @click="submitScore"
        >Submit</v-btn
      >
    </div> -->
    <v-row>
      <v-col cols="7">
        <v-btn
          v-if="value.file_url"
          width="150"
          color="primary"
          @click="$emit('preview')"
          >Preview</v-btn
        ></v-col
      >
      <v-col cols="1" class="mt-2">Marks</v-col>
      <v-col>
        <v-text-field
          v-model="score"
          outlined
          dense
          type="number"
          class="ml-4"
          hide-details
          @blur="submitScore"
        ></v-text-field
      ></v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "AnswerCardDesc",
  props: {
    answer: {
      type: Object,
      required: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    value: { type: [String, Object], default: undefined },
    submitting: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      score: this.value?.score
    };
  },
  methods: {
    submitScore() {
      this.$emit("submit-score", this.score);
    },
    download() {
      window.open(this.value.file_url);
    }
  }
};
</script>

<style scoped></style>
