// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".report-btn {\n  text-transform: none;\n  font-weight: normal !important;\n}\n.p-point {\n  font-size: 14px;\n  color: #8e8e8e;\n}\n.q-a-card {\n  background: #ffffff;\n  border: 1px solid #e4e4e4;\n  box-sizing: border-box;\n  box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.1);\n  border-radius: 10px;\n}", ""]);
// Exports
exports.locals = {
	"primary": "#0099dc",
	"orange": "#FC6222",
	"decline": "#ff4848"
};
module.exports = exports;
