var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    [
      !_vm.readonly
        ? _c(
            "div",
            [
              _c("v-text-field", {
                on: {
                  blur: function ($event) {
                    _vm.$emit("input", _vm.studentFibAnswer.trim())
                  },
                },
                model: {
                  value: _vm.studentFibAnswer,
                  callback: function ($$v) {
                    _vm.studentFibAnswer = $$v
                  },
                  expression: "studentFibAnswer",
                },
              }),
              _vm.readonly && _vm.answer.explanation
                ? _c(
                    "v-container",
                    { staticClass: "px-0" },
                    [
                      _c("answer-explanation", {
                        attrs: { answers: _vm.answer },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          )
        : _c(
            "v-container",
            [
              _vm.answer.item_text === _vm.value
                ? _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        {
                          staticClass:
                            "pl-2 pr-0 py-2 d-flex align-center justify-center flex-grow-0",
                          attrs: { cols: "2", sm: "1", md: "1" },
                        },
                        [
                          _c("v-icon", { attrs: { color: "teal darken-2" } }, [
                            _vm._v("check_circle"),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { staticClass: "pl-2 py-2 d-flex align-center" },
                        [
                          _c("v-text-field", {
                            attrs: { readonly: "" },
                            model: {
                              value: _vm.value,
                              callback: function ($$v) {
                                _vm.value = $$v
                              },
                              expression: "value",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _c(
                    "div",
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            {
                              staticClass:
                                "pl-2 pr-0 py-2 d-flex align-center justify-center flex-grow-0",
                              attrs: { cols: "2", sm: "1", md: "1" },
                            },
                            [
                              _c(
                                "v-icon",
                                { attrs: { color: "red accent-2" } },
                                [_vm._v("cancel")]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { staticClass: "pl-2 py-2 d-flex align-center" },
                            [
                              _c("v-text-field", {
                                attrs: { readonly: "" },
                                model: {
                                  value: _vm.value,
                                  callback: function ($$v) {
                                    _vm.value = $$v
                                  },
                                  expression: "value",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("v-row", [
                        _vm._v(" Correct Answer: "),
                        _c("strong", { staticClass: "ml-1" }, [
                          _vm._v(_vm._s(_vm.answer.item_text)),
                        ]),
                      ]),
                    ],
                    1
                  ),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }