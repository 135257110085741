<template>
  <m-container v-if="hasExplanation" class="px-0 py-0 q-a-card">
    <v-row justify="center">
      <v-col class="py-1">
        <v-expansion-panels flat>
          <v-expansion-panel>
            <v-expansion-panel-header style="font-size: 16px;"
              >Explanation
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <p v-if="!$language">
                The Correct Answer Explanation is indicated by
                <span style="color: #00C853">Green</span> color.
              </p>
              <p v-else>
                সঠিক উত্তরের ব্যাখ্যা
                <span style="color: #00C853">সবুজ</span> রঙ দ্বারা নির্দেশ করা
                হয়েছে।
              </p>

              <div v-for="answer in filteredAnswer" :key="answer.id">
                <p
                  :style="`color: ${styles.answer[answer.is_correct]}`"
                  class="mb-1"
                >
                  Answer option {{ answer.id }} Explanation:
                </p>
                <p>{{ answer.explanation }}</p>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>
  </m-container>
</template>

<script>
export default {
  name: "AnswerExplanation",
  components: {},
  mixins: [],
  props: {
    answers: { type: Array, required: true }
  },
  data() {
    return {};
  },
  computed: {
    styles() {
      return {
        answer: ["#737373", "#00c853"]
      };
    },
    filteredAnswer() {
      return this.answers.filter(answer => {
        return "explanation" in answer;
      });
    },
    hasExplanation() {
      return this.filteredAnswer.length > 0;
    }
  },
  methods: {}
};
</script>

<style lang="scss"></style>
