<template>
  <m-container class="d-flex justify-center">
    <v-container class="pa-0">
      <v-container class="q-a-card">
        <v-container>
          <div class="mb-2" style="text-align: justify">
            <quill-editor
              v-if="question.item_text"
              :disabled="true"
              :value="question.item_text"
              :options="{
                ...editorOptions
              }"
            />
          </div>
          <div v-if="question.file_url" class="mb-2 d-flex justify-center">
            <v-img
              :src="question.file_url"
              max-width="300"
              max-height="300"
              contain
            ></v-img>
          </div>
          <v-divider></v-divider>
        </v-container>
        <v-container v-if="questionType === 'mcq'" class="px-8 pt-0">
          <div v-for="answer in question.answers" :key="answer.id">
            <answer-opt-p
              :answer="answer"
              :selected="value.includes(answer.id)"
              :correct="!!answer.is_correct"
              :wrong="
                answer.is_correct !== undefined &&
                  value.includes(answer.id) &&
                  !answer.is_correct
              "
              @click.native="toggleSelect(answer.id)"
            ></answer-opt-p>
            <v-container v-if="readonly && answer.explanation" class="px-0">
              <answer-explanation :answers="question.answers" />
            </v-container>
          </div>
        </v-container>
        <v-container v-else-if="questionType === 'fib'" class="px-8 pt-0">
          <fib-answer-card
            v-if="readonly"
            :answer="question.answers[0]"
            :readonly="readonly"
            :value="value[0]"
            @input="$emit('input', $event)"
          ></fib-answer-card>
          <fib-answer-card
            v-else
            :readonly="readonly"
            :value="value[0]"
            @input="$emit('input', $event)"
          ></fib-answer-card>
        </v-container>
        <v-container v-else-if="questionType === 'desc'" class="px-8 pt-0">
          <answer-card-desc
            readonly
            :value="value[0]"
            :submitting="submitting"
            @preview="$emit('preview')"
            @submit-score="$emit('submit-score', $event)"
          ></answer-card-desc>
        </v-container>
      </v-container>
    </v-container>
  </m-container>
</template>

<script>
import AnswerCardDesc from "#ecf/exam/components/AnswerCardDesc";
import AnswerOptP from "#ecf/exam/components/AnswerOptP.vue";
import AnswerExplanation from "#ecf/exam/components/AnswerExplanation";
import FibAnswerCard from "#ecf/exam/components/FibAnswerCard";
export default {
  name: "QuestionAnswerCardP",
  components: { AnswerCardDesc, FibAnswerCard, AnswerExplanation, AnswerOptP },
  props: {
    question: {
      type: Object,
      required: true
    },
    readonly: { type: Boolean, default: false },
    value: { type: Array, default: () => [] },
    submitting: Boolean
  },
  data() {
    return {
      editorOptions: {
        modules: {
          toolbar: false
        }
      }
    };
  },
  computed: {
    questionType() {
      return this.question.type;
    }
  },
  created() {},
  methods: {
    correctOrNot(num) {
      if (num == 1) return true;
      else return false;
    },
    toggleSelect(id) {
      // if (this.value === id) {
      //   this.$emit("input", undefined);
      // }
      // if (this.value !== id) {
      this.$emit("input", id);
      // }
    },
    recordFibAnswer(answerText) {
      this.$emit("input", answerText.trim());
    }
  }
};
</script>

<style lang="scss">
.report-btn {
  text-transform: none;
  font-weight: normal !important;
}
.p-point {
  font-size: 14px;
  color: #8e8e8e;
}
.q-a-card {
  background: #ffffff;
  border: 1px solid #e4e4e4;
  box-sizing: border-box;
  box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}
</style>
